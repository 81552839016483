import Fingerprint from "fingerprintjs2";

declare global {
  interface Window {
    fingerprint_config?: {
      property?: string;
      reference?: string;
      url?: string;
    };
  }
}

(() => {
  let fingerprint = new Fingerprint();

  function isConfigAvailable(): boolean {
    if (
      !window.fingerprint_config ||
      !Boolean(window.fingerprint_config.property) ||
      !Boolean(window.fingerprint_config.reference) ||
      !Boolean(window.fingerprint_config.url)
    ) {
      console.log("Fingerprint config is not set, ignoring fingerprinting");
      return false;
    }
    return true;
  }

  function createPostData(
    result: any,
    component_array: any[]
  ): {
    json: { [key: string]: any };
    url: string;
  } {
    let components: { [key: string]: any } = {};
    for (let item of component_array) {
      if (item.value) {
        components[item.key] = item.value;
      }
    }

    let fonts = [];
    if (components.js_fonts) {
      fonts = components.js_fonts;
    }

    let plugins = [];
    if (components.regular_plugins) {
      plugins = components.regular_plugins;
    } else if (components.ie_plugins) {
      plugins = components.ie_plugins;
    }

    return {
      json: {
        property: window.fingerprint_config?.property,
        reference: String(window.fingerprint_config?.reference),

        user_agent: components.user_agent,
        screen_print: `${components.available_resolution[0]}x${components.available_resolution[1]}`,
        plugin_list: plugins.join(),
        font_list: fonts.join(),
        local_storage: components.local_storage,
        session_storage: components.session_storage,
        language: components.language,
        canvas_print: components.canvas,
        webgl_print: components.webgl,
      },
      url: window.fingerprint_config?.url as string,
    };
  }

  function sendFingerprint() {
    fingerprint.get((result, component_array) => {
      if (!isConfigAvailable()) return;
      let postData = createPostData(result, component_array);

      fetch(postData.url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData.json),
        credentials: "include",
      })
        .then((response) => {
          if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        })
        .catch((e) => console.log("An error occurred: " + e));
    });
  }
  window.addEventListener("DOMContentLoaded", () => {
    setTimeout(sendFingerprint, 1000);
  });
})();

export {};
